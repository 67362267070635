<template>
  <main class="OrdersImport">
    <TheSubHeader
      icon="download-alt"
      title="Importar"
      page-title-tag="Orders Import"
      type="tabs"
      :tabs="tab"
      :actions="['see', 'export', 'addnew']"
      organizer-gap="1rem"
      @changeTab="changeCurrentTab($event)"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.incomingOrderButton"
          icon="package-request"
          color="dark"
          label="Recebimento de físico"
          :track="trackSubheaderButtonString('Orders Import', 'Orders Incoming')"
          @click="goToIncomingOrders"
        />
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.changeOrderStatusButton"
          icon="export-alt"
          color="dark"
          label="Mudar Status"
          class="TheSubHeader__button"
          :track="trackSubheaderButtonString('Orders Import', 'Orders Change Status')"
          @click="goToChangeStatus"
        />
      </template>
      <template #filter>
        <section v-if="currentTab === 0" class="tab1">
          <!-- Stores -->
          <BaseTreeSelect
            v-model="filters.storeLabel"
            name="Lojista"
            label="Lojista"
            placeholder="Selecione"
            :select-options="null"
            :fetch-options="'stores/fetchStoresWithInfoAsync'"
            @input="parseStoresTransporter"
          />
          <!-- Transporter -->
          <BaseTreeSelect
            v-model="filters.transporterOrder"
            name="Transportadora"
            label="Transportadora"
            placeholder="Selecione"
            :auto-select="true"
            :clearable="false"
            :read-only="disabledFields.storesTransporter"
            :select-options="storesTransporter"
          />
          <!-- Archive -->
          <InputFile
            ref="inputFile"
            v-model="filters.orderFile"
            label="Planilha"
            btn-label="Escolha o arquivo"
            accept-files=".xlsx, .xls, .zip, .xml"
          />
          <!-- Import -->
          <BaseButton
            label="Importar arquivo"
            theme="icon-left"
            filled
            color="primary-base"
            text-color="white"
            icon="download"
            :disabled="disabledFields.orderImported"
            @click="ordersImport"
          />
        </section>
      </template>
    </TheSubHeader>
    <div class="OrdersImport__Wrapper">
      <div class="OrdersImport__Content">
        <div class="OrdersImport__Filter">
          <h1 class="OrdersImport__Filter__Title">
            HISTÓRICO DE IMPORTAÇÕES
          </h1>
          <!-- Filter Table -->
          <div class="OrdersImport__Filter__Content">
            <!-- Period -->
            <BaseDatePicker
              v-model="formattedDateRange"
              label="Período"
              @input="search()"
            />
            <!-- Stores -->
            <BaseTreeSelect
              v-model="historic.storeLabel"
              name="Lojista"
              label="Lojista"
              placeholder="Selecione"
              :select-options="null"
              :fetch-options="'stores/fetchStoresWithInfoAsync'"
              @input="search()"
            />
            <!-- Transporters -->
            <BaseTreeSelect
              v-model="historic.storeTransporters"
              name="Transportadora"
              label="Transportadora"
              placeholder="Selecione"
              :select-options="transporters"
              @input="search()"
            />
            <!-- Status -->
            <BaseTreeSelect
              v-model="historic.historicSelected"
              name="Status"
              label="Status"
              placeholder="Selecione"
              :select-options="historicOptions"
              @input="search()"
            />
          </div>
          <div class="OrdersImport__Table">
            <DataTable
              :header="importedOrders.data.headers"
              :table="importedOrdersWithLoading"
              is-sticky
              @see="errorsModal"
              @download="downloadExcel"
            />
            <Pagination @page="search()" @paginate="search()" />
          </div>
        </div>
      </div>
    </div>
    <ErrorList v-if="showErros" :list="errorSort" class="OrdersImport__ErrorList" @close="closeErrorList" />
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import {BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { InputFile, Pagination, ErrorList, DataTable } from '@/components/molecules'
import { orders } from '@/api'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'OrdersImport',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    ErrorList,
    InputFile,
    Pagination
  },
  data() {
    return {
      //Component
      currentTab: 0,
      tab: [
        {
          id: 0,
          title: 'Pedidos'
        }
      ],

      //Filters
      filters: {
        storeLabel: null,
        transporterOrder: null,
        orderFile: null
      },

      //Historic
      historic: {
        dateRange: {
          init: null,
          end: null,
        },
        storeLabel: null,
        historicSelected: null,
      },

      //Select Options
      historicOptions: [
        { id: 0, name: 'Todos' },
        { id: 1, name: 'Andamento' },
        { id: 2, name: 'Concluída' },
        { id: 3, name: 'Concluída com erros' }
      ],

      //Errors
      showErros: false,

      //Helpers
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,
    }
  },
  computed: {
    ...mapState({
      //Filters
      storesTransporter: state => state.transporters.storesTransporter,
      //Historic
      transporters: state => state.transporters.transporters,
      //Table
      importedOrders: state => state.orders.importOrders,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      //Helpers
      permissions: state => state.auth.permissions,
    }),

    ...mapGetters({
      errorSort: 'orders/errorSort',
      importedOrdersWithLoading: 'orders/importedOrdersWithLoading'
    }),

    //Disabled Fields
    disabledFields() {
      return {
        //Filters
        storesTransporter: this.storesTransporter.length === 0,
        //Buttons
        orderImported:
          !this.filters.storeLabel ||
          !this.filters?.transporterOrder?.id ||
          !this.filters.orderFile,
      }
    },

    //Filters
    formattedDateRange: {
      get: function() {
        return [this.historic.dateRange.init, this.historic.dateRange.end]
      },
      set: function(newValue) {
        this.historic.dateRange.init = newValue[0]
        this.historic.dateRange.end = newValue[1]
      },
    },
  },

  mounted() {
    this.fetchTransporters()
    this.search()
  },

  methods: {
    ...mapActions({
      //Filters
      fetchStoresTransporter: 'transporters/fetchStoresTransporter',
      //Historic
      fetchTransporters: 'transporters/fetchTransporters',
      //Table
      fetchImportOrders: 'orders/fetchImportOrders',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      //Import
      importOrders: 'orders/importOrders',
      //Errors
      fetchImportErrors: 'orders/fetchImportErrors',
      //Helpers
      setLoading: 'loading/setLoading',
    }),

    //Component
    changeCurrentTab(e) {
      this.currentTab = e.id
    },

    //Filters
    parseStoresTransporter(){
      if (this.filters.storeLabel) {
        this.fetchStoresTransporter(this.filters.storeLabel.id)
      }
    },

    //Import
    async ordersImport() {
      let formData = new FormData()
      formData.append('store_id', this.filters?.storeLabel?.id)
      formData.append('transporter_id', this.filters?.transporterOrder?.id)
      formData.append('file', this.filters?.orderFile)
      
      const res = await this.importOrders(formData)
      this.resetFile()
      
      if(!res.trace) {
        this.filters.storeLabel = null
        this.filters.transporterOrder = null
        this.resetSearch()
      }
      
    },

    //Search
    search(loading = true){
      if (loading) {
        this.setLoading(true)
      }
      const params = {
        obj: {
          date_start: this.historic?.dateRange.init,
          date_end: this.historic?.dateRange.end,
          store_id: this.historic?.storeLabel?.id,
          transporter_id: this.historic?.storeTransporters?.id,
          orders_import_status_id: this.historic?.historicSelected?.id
        },
        id: this.transporterId,
        paginate: this.paginate,
        page: this.page
      }

      this.fetchImportOrders(params).then(() => {
        this.setPagination(this.importedOrders)
        this.checkfetchImportOrders()
      })
    },

    checkfetchImportOrders() {
      if (
        JSON.stringify(this.importedOrders.data.content).indexOf('Andamento') > 0 && 
        this.$route.name === 'ordersImport'
      ) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.importedOrders)
        } else {
          clearTimeout(this.time)
          this.setPagination(this.importedOrders)
        }
      }
    },

    //Handlers
    downloadExcel(data){
      this.downloadImported(data.id)
    },
    async downloadImported(id) {
      this.setLoading(true)
      await orders.importedOrders(id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    //Reset
    resetSearch() {
      this.historic.dateRange = {init: null, end: null }
      this.historic.storeLabel = null
      this.historic.storeTransporters = null
      this.historic.historicSelected = null
      this.clearPagination().then(() => this.search())
    },
    resetFile(){
      this.filters.orderFile = null
      this.$refs.inputFile.reset()
    },

    //Errors
    errorsModal(data){
      this.fetchImportErrors(data.id)
      this.showErros = true
    },
    closeErrorList(){
      this.showErros = false
    },

    //Helpers
    goToIncomingOrders() {
      this.$router.push('/orders/incoming')
    },

    goToChangeStatus() {
      this.$router.push('/orders/changeStatus')
    },
  }
}
</script>

<style lang="scss" scoped>
.OrdersImport {
  .tab1,
  .tab2 {
    display: grid;
    align-items: end;
    grid-gap: 1rem;
    @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .tab1 {
    grid-template-columns: repeat(4, 1fr);
  }
  .tab2 {
    grid-template-columns: repeat(4, 1fr);
  }

  &__Wrapper {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__Content {
    grid-area: main;
    margin-bottom: 50px;
   
  }

  &__Filter {
    padding-top: 30px;
    &__Title {
      font-weight: $font-weight-bold;
      font-style: normal;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
      margin-bottom: 20px;
    }
    &__Content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      align-items: end;
      margin-bottom: 50px;
      @media #{$mobile-view} {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  // &__ErrorList{
    
  // }
  
  &--disable{
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
</style>